<template>
  <v-hover v-slot="{ hover }">
    <v-btn
      :color="(hover || isFavourited) ? 'red' : 'grey'"
      class="d-flex align-center"
      text
      @click.prevent="clickHandler"
    >
      <v-icon left>{{ (hover || isFavourited) ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon>
      <p
        v-if="showCount"
        class="body-2 mb-0 body-2"
      >{{ favouritesCount }}
      </p>
    </v-btn>
  </v-hover>
</template>

<script>
export default {
  name: 'FavouriteButton',
  props: {
    isFavourited: {
      type: Boolean,
      required: true,
      default: () => (false),
    },
    favouritesCount: {
      type: [Number, String],
      required: true,
      default: () => (0),
    },
    showCount: {
      type: Boolean,
      required: true,
      default: () => (true),
    },
  },
  methods: {
    clickHandler() {
      this.$emit('toggle');
    },
  },
};
</script>
